<template>
  <div :class="['nav_box',navTarget?'active':'']">
    <div class="nav_center">
<!--      <img class="logo" src="@/static/img/home/home-top.png"/>-->
      <img class="logo" src="../../static/img/home/logo-top.png"/>
      <ul class="nav_right">
        <li :class="$route.path=='/'?'active':''">
          <router-link class="active" to="/">首页</router-link>
        </li>
        <li :class="$route.path=='/Scheme'?'active':''">
          <router-link to="/Scheme">解决方案</router-link>
        </li>
        <li :class="$route.path=='/Product'?'active':''">
          <router-link to="/Product">产品中心</router-link>
        </li>
        <li :class="$route.path=='/Informations'?'active':''">
          <router-link to="/Informations">资讯动态</router-link>
        </li>
        <li :class="$route.path=='/ClassicCase'?'active':''">
          <router-link to="/ClassicCase">经典案例</router-link>
        </li>
        <li :class="$route.path=='/Server'?'active':''">
          <router-link to="/Server">服务支持</router-link>
        </li>
        <li :class="$route.path=='/about'?'active':''">
          <router-link to="/about">关于我们</router-link>
        </li>
        <li :class="$route.path=='/Contact'?'active':''">
          <router-link to="/Contact">联系我们</router-link>
        </li>
        <li style="padding-right: 0px">
          <a href="https://yun.jingfang.net">
            <div class="login-t">申请试用</div>
          </a>
        </li>
        <li>
          <a href="https://yun.jingfang.net">
            <div class="login-tr">注册</div>
          </a>
        </li>
      </ul>
<!--      <ul class="nav_right">-->
<!--        <li :class="$route.path=='/'?'active':''">-->
<!--          <router-link class="active" to="/">首页</router-link>-->
<!--        </li>-->
<!--        <li class="list_box">-->
<!--          <a href="https://yun.jingfang.net">经方云平台</a>-->
<!--        </li>-->
<!--        <li :class="$route.path=='/Mobile'?'active':''">-->
<!--          <router-link to="/Mobile">经方健康APP</router-link>-->
<!--        </li>-->
<!--        <li :class="$route.path=='/Applications'?'active':''">-->
<!--          <router-link to="/Applications">产品与应用</router-link>-->
<!--        </li>-->
<!--        <li :class="$route.path=='/Information'?'active':''">-->
<!--          <router-link to="/Information">行业动态</router-link>-->
<!--        </li>-->
<!--        &lt;!&ndash; <li :class="$route.path=='/'?'active':''">-->
<!--          <router-link to="/">产品下载</router-link>-->
<!--        </li>&ndash;&gt;-->
<!--        <li :class="$route.path=='/about'?'active':''">-->
<!--          <router-link to="/about">关于我们</router-link>-->
<!--        </li>-->
<!--      </ul>-->
    </div>
  </div>
</template>
<script>
export default {
  name: 'TopNav',
  data() {
    return {
      navTarget: false,
      scroll: 0
    }
  },
  props: {
    alwaysShow: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.alwaysShow) {
      this.navTarget = true
      return
    }
    window.addEventListener('scroll', this.handlerScroll)
  },
  methods: {
    handlerScroll(e) {
      this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop
    }
  },
  watch: {
    scroll(val) {
      if (val > 120) {
        this.navTarget = true
      } else {
        this.navTarget = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.nav_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  line-height: 80px;
  z-index: 2000;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.05);
  background-color: white;
  transition: all 0.5s;

  &.active {
    //background: #3565d7;
    color: #AE7B40;

    & .nav_list {
      background: #3565d7 !important;
    }
  }

  .nav_center {
    width: 1200px;
    margin: 0 auto;

    .logo {
      width: 133px;
      height: 40px;
      float: left;
      margin-top: 20px;
    }

    .nav_right {
      float: right;

      li {
        position: relative;
        display: inline-block;
        padding: 0 20px;
        z-index: 9;

        a {
          color: #666;
          padding-bottom: 8px;
        }

        &:hover {
          cursor: pointer;
        }
        a:hover {
          color: #AE7B40;
        }
        &.list_box:hover {
          .nav_list {
            display: block;
            height: auto;
            // top: 60px;
          }
        }

        .nav_list {
          position: absolute;
          display: none;
          top: 60px;
          left: 0;
          width: 100%;
          height: 0;
          transition: all 0.5s;
          text-align: center;
          color: #cfd0e5;
          background: initial;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;

          p:hover {
            a {
              //color: #AE7B40;

              color: #fff;
            }
          }
        }
      }

      li.active {
        border-top: 2px solid #AE7B40 !important;
        box-sizing: border-box;

        a {
          color: #AE7B40 !important;
          font-weight: 550;
          border-bottom: 2px solid #fff;
        }
      }
    }
  }
}
.login-t{
  background-color: #AE7B40;
  color: white;
  line-height: 32px;
  width: 100px;
  height: 32px;
  border-radius: 16px;
}
.login-tr{
  background-color: #fff;
  color: #AE7B40;
  line-height: 32px;
  width: 100px;
  height: 32px;
  border: 1px solid #AE7B40;
  border-radius: 16px;
}
</style>
