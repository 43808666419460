<template>
  <div class="footer">
    <div class="fy_center_box">

      <img class="logo1" src="../../static/img/home/logo-foot2.png" height="93" width="232"/>
      <ul style="margin-right:116px;">
        <li class="title">联系我们</li>
        <li class="tel">0371-60523888</li>
        <li class="mess">
          <img src="@/static/image/index/email.png"/>
          <span>csc@jingfang.net</span>
        </li>
        <li class="mess">
          <img src="@/static/image/index/address.png"/>
<!--          河南省郑州市高新技术产业开发区枫香街与雪兰路交汇处天健湖智联网产业园3号楼13层-->
          <span title="河南省郑州市高新技术产业开发区枫香街与雪兰路交汇处天健湖智联网产业园3号楼13层">河南省郑州市天健湖产业园3号楼13层</span>
        </li>
      </ul>
      <ul class="nav_bottom" style="margin-right:120px;">
        <li class="title">站内导航</li>
        <!-- <li>首页</li>
        <li>产品应用</li>
        <li>行业动态</li>
        <li>关于我们</li>-->
        <!--        <li>-->
        <!--          <router-link to="/Mobile">经方健康APP</router-link>-->
        <!--        </li>-->
        <li>
          <router-link to="/Product">产品与应用</router-link>
        </li>
        <li>
          <router-link to="/Informations">新闻资讯</router-link>
        </li>
        <li>
          <router-link to="/about">关于我们</router-link>
        </li>
        <li>
          <router-link to="/HosContact">医疗人才资讯</router-link>
        </li>
      </ul>
      <ul>
        <li class="title">关注我们</li>
        <li>
<!--          <div class="cont_us">-->
<!--            <img alt="下载APP" src="@/static/image/common/qr.png"/>-->
<!--            <p>下载经方云APP</p>-->
<!--          </div>-->
          <div class="cont_us">
            <img alt="经方云公众号" src="@/static/image/common/gzh.png"/>
            <p>关注经方云公众号</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="bottom">
      <a style="padding-right: 12px;border-right: 1px solid #999; color: #999">Copyright © 2016-2026 JINGFANGYUN All
        Rights Reserved</a>
      <img alt="" src="@/static/image/common/ga.png">
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002655" style="padding-right: 12px;"
         target="_blank">豫公网安备41010702002655号</a>
      <a href="http://beian.miit.gov.cn/#/Integrated/recordQuery" style="padding-left: 12px;border-left: 1px solid #999"
         target="_blank">豫ICP备19034173号-1</a>
      <a href="https://xyt.xcc.cn/getpcInfo?sn=1466573013954924544&language=CN&certType=8&url=*.jingfang.net"
         target="_blank" style="position: relative;display: inline-block;height: 38px;">
        <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div>
        <embed src="https://program.xinchacha.com/web/1466573013954924544=*.jingfang.net.svg" width="103" height="38"
               type="image/svg+xml" class="zhengShu" pluginspage="//www.adobe.com/svg/viewer/install/"/>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: ''
}
</script>
<style lang="scss" scoped>
.footer {
  position: relative;
  width: 100%;
  min-width: 1200px;
  height: 330px;
  padding-top: 50px;
  background: #282828;

  .logo1 {
    float: left;
    //width: 135px;
    //height: 160px;
    margin-right: 108px;
    margin-top: 40px;
  }

  ul {
    float: left;
    color: #A5A5A5;
  }

  .nav_bottom li {
    padding-bottom: 14px;
    text-align: left;

    a {
      color: #A5A5A5;
    }

    &.title {
      padding-bottom: 0;
    }
  }

  .tel {
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 14px;
    color: white;
    text-align: left;
  }

  .title {
    margin-bottom: 24px;
    text-align: left;
    color: #dfdfdf;
  }

  .mess {
    display: flex;
    align-items: center;
    line-height: 20px;
    height: 20px;
    margin-bottom: 20px;

    img {
      display: inline-block;
      height: 18px;
      width: 18px;
      margin-right: 10px;
    }

    span {
      // margin-top: 10px;
      font-size: 14px;
    }
  }

  .cont_us {
    float: left;
    width: 110px;
    margin-right: 20px;

    img {
      display: inline-block;
      width: 110px;
      height: 110px;
      margin-bottom: 10px;
      border-radius: 10px;
    }

    p {
      text-align: center;
      color: #A5A5A5;
      font-size: 12px;
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 1200px;
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #333;
    text-align: center;

    img {
      display: inline-block;
      width: 16px;
      vertical-align: middle;
      margin-right: 4px;
      margin-left: 8px;
    }

    a {
      font-size: 12px;
      color: #999;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
.zhengShu{
  position: relative;
  top: 12px;
  left: 20px;
}
</style>
