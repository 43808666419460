<template>
  <div class="hos-contact">
    <TopNav></TopNav>
    <div class="fourth">
      <div class="fourth-1">Talent Service</div>
      <div class="fourth-2">专 注 于 医 疗 行 业 人 才 服 务</div>
      <div class="fourth-3"></div>
      <div class="fourth-4">搭建医疗机构与专业人才交流的信息平台</div>
    </div>

    <div class="add">
      <div style="margin-bottom: 40px" v-for="(item,i) in list" :key="i">
        <el-card shadow="always">
          <div class="add-item">
            <div style="position: relative" @click="openHosp(item)">
              <img class="img-hosp" :src="'/image/'+ item.img" height="220" width="300"/>
              <div class="img-hosp-top">
                <span class="img-hosp-top1">{{ item.name }}</span>
              </div>
            </div>
            <div class="add-item-r">
              <div class="add-item-r1" v-for="(p,q) in item.recruitmentList" @click="openWorker(p,item)">
                <div class="add-item-r1-name">
                  <div class="add-item-r1-name1" :title="p.name">{{ p.name }}</div>
                  <div class="add-item-r1-name2">
                    <span class="add-item-r1-name3" :title="p.negotiable?'面议':p.salaryRange">{{ p.negotiable?'面议':p.salaryRange }}</span>
                    <span class="add-item-r1-name4" :title="p.place">
                      <img style="position: relative;top: 2px" src="../static/img/contact/address.png" height="17" width="14"/>
                      {{p.place}}
                    </span>
                  </div>

                </div>
                <div class="add-item-r1-pos">
                  <span>{{p.education}} | </span>
                  <span>{{p.num}}人 | </span>
                  <span>{{p.experience}} | </span>
                  <span v-if="p.benefits.length>0">{{p.benefits[0]}} <span v-if="p.benefits.length>1"> | </span></span>
                  <span v-if="p.benefits.length>1">{{p.benefits[1]}}  </span>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div :class="['three',all<2||all==2?'hiden':'']">
      <div class="fy_center_box">
        <el-pagination
            :pageSize="params.limit"
            :total="all"
            @current-change="currentChange"
            :current-page.sync="params.page"
            background
            layout="prev, pager, next"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
        :title="workerData.name"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="970px"
        :center="false"
        top="8vh"
        :close-on-click-modal="false"
        :before-close="handleClose">
      <div>
        <el-scrollbar style="height: 500px">

          <el-row>
            <el-col :span="7">
              <div class="grid-content bg-purple">薪酬范围：
              <span style="color: #FA6400" v-if="!workerData.negotiable">{{workerData.minSalary}}-{{workerData.maxSalary}}元/月</span>
              <span style="color: #FA6400" v-else>面议</span>
            </div>
            </el-col>
            <el-col :span="6"><div class="grid-content bg-purple">工作地点：{{ workerData.place }}</div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">招聘人数：{{ workerData.num }}人</div></el-col>
            <el-col :span="5"><div class="grid-content bg-purple">工作年限：{{ workerData.experience }}</div></el-col>
          </el-row>
          <el-row style="margin: 16px 0">
            <el-col :span="7"><div class="grid-content bg-purple">要求学历：{{ workerData.education }}</div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">所属科室：{{ workerData.department }}</div></el-col>
            <el-col :span="6"><div class="grid-content bg-purple">职位类别：{{ workerData.category }}</div></el-col>
            <el-col :span="5"><div class="grid-content bg-purple">发布时间：{{ workerData.releaseDate }}</div></el-col>
          </el-row>
          <div style="text-align: left" v-if="workerData.benefits">
            <div class="fuli-box" v-for="(n,i) in workerData.benefits" :key="i">{{n}}</div>
          </div>
          <div class="hosp-item">
            {{hospData.name}}
            <span @click="loopHosp">查看医院信息</span>
          </div>
          <div style="text-align: left" >
            <div v-html="workerData.description"></div>
          </div>
        </el-scrollbar>


      </div>



    </el-dialog>
    <el-dialog
        :title="hospData.name"
        :visible.sync="dialogVisibleHosp"
        width="970px"
        :center="false"
        :destroy-on-close="true"
        top="8vh"
        :close-on-click-modal="false"
        :before-close="handleClose1">
      <div style="text-align: left">
        <el-scrollbar style="height: 500px">
          <img class="img-hosp" :src="'/image/' + hospData.img" height="220" width="300"/>

          <div style="margin-top: 20px">
            <div v-html="hospData.description"></div>
          </div>
          <div style="margin-top: 20px">
            <div>联系方式：</div>
            <div>联系电话：{{ hospData.phone }}</div>
            <div>企业邮箱：{{ hospData.email }}</div>
            <div>通讯地址：{{ hospData.address }}</div>
          </div>

        </el-scrollbar>


      </div>



    </el-dialog>

    <Footer></Footer>
  </div>
</template>

<script>
import TopNav from "@/components/Nav/TopNav.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  data() {
    return {
      activeName: 'first',
      hospData: {},
      formData: {
        name: "",
        phone: "",
        email: "",
        content: ""
      },
      isLoading: false,
      index: '0',
      list: [],
      params: {
        page: 1,
        limit: 4,
      },
      dialogVisible: false,
      dialogVisibleHosp: false,
      workerData:{},
      all: 0, //总页数
    };
  },
  components: {
    TopNav,
    Footer,
  },
  methods: {
    currentChange(val) {
      this.params.page = val
      this.refresh()
    },
    refresh() {
      this.$Api.hospitalRecruitmentList(this.params).then(r => {
        this.list = r.data
        this.list.forEach(val=>{
          val.recruitmentList.forEach(v=>{
            if (v.benefits){
              v.benefits = JSON.parse(v.benefits)
            } else {
              v.benefits = []
            }

          })
        })
        this.all = r.total
      }).catch(err => {
        this.$message.error(err || "服务器开小差，请稍后重试！");
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleClose1() {
      this.dialogVisibleHosp = false
    },
    openWorker(p,q){
      this.workerData = p
      this.hospData = q
      this.dialogVisible = true
    },
    openHosp(m){
      this.hospData = m
      this.dialogVisibleHosp = true
    },
    loopHosp(){
      this.dialogVisibleHosp = true
    }
  },
  mounted() {
    this.refresh()
  }
};
</script>

<style lang="scss" scoped>
.fourth {
  width: 100%;
  height: 360px;
  padding-top: 90px;
  margin-top: 80px;
  background: url("./../static/img/contact/con-bg.png") no-repeat center;
  background-size: cover;
  color: white;
  font-size: 40px;

  .fourth-1 {
    font-size: 40px;
    margin-bottom: 8px;
  }

  .fourth-2 {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .fourth-3 {
    width: 570px;
    border-bottom: 1px solid #fff;
    margin: 0 auto;
  }

  .fourth-4 {
    font-size: 24px;
    margin-top: 16px;
  }
}

.add {
  width: 1200px;
  margin: 0 auto;
  text-align: left;

  .add-item {
    width: 1200px;
    display: flex;
    justify-content: left;
    align-items: first;

    .add-item-r {
      //width: 800px;
      margin-left: 39px;
      display: flex;
      flex-wrap: wrap;

      .add-item-r1 {
        width: 370px;
        height: 110px;
        cursor: pointer;
        padding-top: 16px;
        margin-right: 60px;

        .add-item-r1-name {
          //display: flex;
          //justify-content: space-between;
          //align-items: center;

          .add-item-r1-name1 {
            font-size: 20px;
            color: #333333;
            font-weight: 550;
            width: 130px;
            display: inline-block;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap
          }

          .add-item-r1-name2 {
            display: inline-block;
            width: 238px;
            position: relative;
            top: -3px;
            .add-item-r1-name3 {
              display: inline-block;
              font-size: 24px;
              font-weight: 400;
              color: #D69139;
              padding-right: 10px;
              width: 150px;
              overflow:hidden;
              text-overflow:ellipsis;
              white-space:nowrap;
              position: relative;
              top: 2px;
              text-align: right;
            }

            .add-item-r1-name4 {
              display: inline-block;
              height: 28px;
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
              width: 88px;
              position: relative;
              top: -2px;
              overflow:hidden;
              text-overflow:ellipsis;
              white-space:nowrap
            }
          }
        }

        .add-item-r1-pos {
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 5px;
          color: #666666;

        }

        .add-item-r1-num {
          font-size: 16px;
          color: #666666;
        }
      }

      .add-item-r1:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.img-hosp {
  object-fit: cover;
  border-radius: 10px;
}

.img-hosp-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 220px;
  background-color: rgba(0, 0, 0, .3);
  border-radius: 10px;
  cursor: pointer;
  .img-hosp-top1 {
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    display: inline-block;
    border-left: 4px solid #fff;
    padding-left: 10px;
    margin: 12px 0 0 16px;
    cursor: pointer;

  }
}

.three {
  &.hiden {
    opacity: 0;
  }

  padding-bottom: 64px;

  .el-pagination {
    text-align: center;

    /deep/ .btn-prev,
    /deep/ .btn-next,
    /deep/ .el-pager li {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      border-radius: 8px;
      color: #999999;
    }

    /deep/ .el-pager li.active {
      color: #fff;
    }

    /deep/ .btn-prev,
    /deep/ .btn-next {
      color: #ffffff;
      background: #d0d0d0;
    }

    /deep/ .el-icon {
      font-size: 16px;
    }
  }
}

/deep/ .el-dialog__header{
  background-color: #F4F6F7;
  height: 60px;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}
/deep/ .el-dialog__title{
  font-weight: bolder;
}
/deep/ .el-dialog{
  border-radius: 10px;
}
/deep/ .el-dialog__body{
  padding-left: 38px;
  padding-right: 20px;
}
.bg-purple{
  text-align: left;
}
.fuli-box{
  background: #F3EFEB;
  border-radius: 4px;
  height: 41px;
  font-size: 16px;
  color: #8E603B;
  min-width: 92px;
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  margin: 0 18px 16px 0;
}
/deep/ .el-scrollbar__thumb{
 background-color: #AE7B40 !important;
}
/deep/ .el-scrollbar__wrap{
  overflow-x: hidden;
}
/deep/ .el-icon-close{
  font-size: 30px;
}
.hosp-item{
  text-align: left;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: bolder;
  span{
    color: #8E603B;
    cursor: pointer;
    margin-left: 20px;
    font-size: 20px;
    font-weight: normal;
  }
}
/deep/ p{
  margin: 16px 0 !important;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: #AE7B40;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color:#AE7B40; //修改后的背景图颜色
  color: #fff;
}


</style>
